import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ApplicationModel,
  CategoryApplicationModel,
  CategoryModel
} from '../../models/common.model';
import { Subscription } from 'rxjs';
import { CategoriesStoreService } from '../../services/category-observable.service';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'cards-list',
  templateUrl: './cards-list.component.html',
  styleUrls: ['./cards-list.component.scss']
})
export class CardsListComponent implements OnChanges, OnDestroy {
  @Input() theme: string = 'marketplace-default';
  @Input() itemsList: ApplicationModel[] | CategoryApplicationModel[];
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() context: string;
  @Input() title2: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySpecialId: string;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categorySuggestedId: string;
  @Input() dataCategory: CategoryModel;
  @Input() categoryFeatured: CategoryModel;
  @Input() categorySpecial: CategoryModel;
  @Input() categoryPurchased: CategoryModel;
  @Input() categoryMostPurchased: CategoryModel;
  @Input() categorySuggested: CategoryModel;
  @Input() showText: boolean = true;
  @Input() showButtons: boolean = true;
  @Input() showTagsList: boolean = true;
  @Output() refreshDataEmitter = new EventEmitter<void>();
  times: number[] = [];
  className = 'cards-list';
  arrayApplicationFeatured: any = [];
  persistentSearch: string;
  arraySubscriptions: Subscription[] = [];

  constructor(
    private categoriesStoreService: CategoriesStoreService,
    private storageService: StorageService,
    private readonly router: Router,
    private utilsService: UtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemsList !== undefined && changes.itemsList.firstChange) {
      this.prepareData(this.width);
      this.randomApp();
    }
    if (changes.width !== undefined && changes.width.currentValue !== changes.width.previousValue) {
      this.prepareData(this.width);
      this.randomApp();
    }
    if (
      changes.itemsList !== undefined &&
      changes.itemsList.currentValue !== changes.itemsList.previousValue &&
      !changes.itemsList.firstChange
    ) {
      this.prepareData(this.width);
      this.randomApp();
    }
  }

  randomApp() {
    let numberRandom = [];
    this.arrayApplicationFeatured = [];
    if (this.categorySuggested.applications.length > this.times.length) {
      this.utilsService.randomNumber(
        this.categorySuggested.applications,
        numberRandom,
        this.times.length
      );
      numberRandom.forEach((idx) => {
        this.arrayApplicationFeatured.push(this.categorySuggested.applications[idx]);
      });
    } else {
      this.arrayApplicationFeatured = this.categorySuggested.applications;
    }
  }

  prepareData(width) {
    this.times = this.utilsService.prepareCards(width);
    return this.times;
  }

  onClickMoreButton(id: string): void {
    console.log(this.context);
    if (this.context !== '/private/catalogue' && this.context !== '/private/result') {
      if (this.dataCategory !== undefined) {
        if (
          this.dataCategory._id === this.categoryPurchasedId ||
          this.dataCategory._id === this.categorySpecialId
        ) {
          this.storageService.resetBread();
          this.storageService.setBread('home', 'home', 'Home', undefined);
        } else {
          this.storageService.setBread(
            'category-detail',
            '',
            this.dataCategory.name,
            this.dataCategory._id
          );
        }
      } else {
        this.storageService.resetBread();
        this.storageService.setBread('home', 'home', 'Home', undefined);
      }
    }
    if (this.context === '/private/catalogue') {
      this.storageService.resetBread();
      this.storageService.setBread('home', 'home', 'Home', undefined);
    }
    if (this.context === '/private/result') {
      console.log('results');
      this.persistentSearch = this.storageService.getStoreSearch();
      console.log(this.persistentSearch);
      this.storageService.setStorePersistentSearch(this.persistentSearch);

      this.storageService.resetBread();
      this.storageService.setBread('home', 'home', 'Home', undefined);
    }
    this.storageService.setUrl('application-detail/' + id);
    this.categoriesStoreService.setStateMenu('close');

    this.router.navigate(['application-detail', id]);
  }

  onClickMoreHorizontalButton(id: string): void {
    this.storageService.setUrl('application-detail/' + id);
    this.categoriesStoreService.setStateMenu('close');
    this.router.navigate(['application-detail', id]);
  }

  refreshData() {
    this.refreshDataEmitter.emit();
  }

  ngOnDestroy() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}