import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationModel,
  CategoryApplicationModel,
  CategoryModel,
  CommonLabels
} from 'app/common/models/common.model';
import { commonLabels } from 'app/common/common.labels';
import { ResizeWindowService } from 'app/common/services/resize-window.service';
import { CategoriesStoreService } from '../../common/services/category-observable.service';
import { StorageService } from '../../common/services/storage.service';
import { UtilsService } from '../../common/services/utils.service';
import { SnackMessageService } from '../../common/services/snackMessage.service';
import { Subscription } from 'rxjs';
import { ApplicationsService } from '../../common/services/applications.service';
import { AuthService } from '../../common/services/auth.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {
  theme = 'marketplace-default';
  className = 'app-category';
  isDesktop: boolean = false;
  width: number = 0;
  labels: CommonLabels = commonLabels;
  dataCategories: CategoryModel[];
  dataCategory: CategoryModel;
  results: ApplicationModel[] | CategoryApplicationModel[];
  dataCategoryId: string;
  categoryPurchasedId: string;
  categoryMostPurchasedId: string;
  categoryFeaturedId: string;
  categorySuggestedId: string;
  categorySpecialId: string;
  categoryFeatured: CategoryModel = undefined;
  categorySuggested: CategoryModel = undefined;
  categorySpecial: CategoryModel = undefined;
  categoryMostPurchased: CategoryModel = undefined;
  categoryPurchased: CategoryModel = undefined;
  arrayApplicationFeatured: ApplicationModel[] | CategoryApplicationModel[] = [];
  isLoading: boolean = true;
  showText: boolean = true;
  menuState: string = 'close';
  img = 'empty-library';
  title = 'It looks like your library is empty.';
  description =
    'Use the search bar above to add a Marketplace app, or check out the Barbara Basics.';
  buttonText = 'Visit Barbara Basics';
  buttonUrl = '';
  arraySubscriptions: Subscription[] = [];

  constructor(
    private resizeWindowService: ResizeWindowService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private storageService: StorageService,
    private categoriesStoreService: CategoriesStoreService,
    private applicationsService: ApplicationsService,
    private snackMessageService: SnackMessageService,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    let url = this.storageService.getCategoryId();
    console.log(url);
    if (url === null) {
      this.router.navigate(['/public/_home']);
    } else {
      this.dataCategoryId = url.split('/').pop();
      console.log(this.dataCategoryId);
      this.initSubscriptions();
    }
  }

  initSubscriptions() {
    this.initCategories();
    this.initPurchasedCategory();
    this.initWidth();
    this.initDesktop();
    this.initMostPurchasedCategory();
    this.initSpecialCategory();
    this.initCategoryFeatured();
    this.initCategorySuggested();
    this.initStateMenu();
  }

  initCategories() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getCategoryList().subscribe({
        next: (data) => {
          this.dataCategories = data;
          this.dataCategory = this.dataCategories.find(
            (category) => category._id === this.dataCategoryId
          );
          console.log(this.dataCategory);
          this.storageService.resetBread();
          this.storageService.setBread('home', 'home', 'Home', undefined);
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initDesktop() {
    this.resizeWindowService.isDesktop$.subscribe({
      next: (value: boolean) => {
        this.isDesktop = value;
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  initWidth() {
    this.resizeWindowService.width$.subscribe({
      next: (value: number) => {
        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.width = value;
            this.isLoading = true;
            this.prepareDataCategory(this.dataCategoryId);
          },
          500,
          'loadingCategory'
        );
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  prepareDataCategory(categoryId: string) {
    this.results = undefined;
    if (categoryId === this.categoryPurchasedId) {
      this.results = this.categoryPurchased.applications;
      this.isLoading = false;
    } else {
      this.getApplicationsSearch(categoryId);
    }
  }

  getApplicationsSearch(categoryId) {
    this.applicationsService.getApplicationsSearch('', 0, 100, '', categoryId).subscribe({
      next: (data: ApplicationModel[] | CategoryApplicationModel[]) => {
        this.results = data['applicationList'];
        this.addTagsList(this.results);
        window.scrollBy(0, -window.innerHeight);
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
        if (this.authService.isLoggedIn()) {
          this.router.navigate(['home']);
        } else {
          this.authService.closeSession();
        }
      }
    });
  }

  addTagsList(appsList) {
    let _appsList = [];
    appsList.forEach((app, index: number) => {
      _appsList.push(app);
      let _dataTagsList = [];
      if (app.categories.find((category) => category.type === 2) !== undefined) {
        if (!_dataTagsList.includes('In library')) {
          _dataTagsList.push('In library');
        }
      }
      if (app.categories.find((category) => category.type === 3) !== undefined) {
        if (!_dataTagsList.includes('Best Seller')) {
          _dataTagsList.push('Best Seller');
        }
      }
      if (
        app.categories.find((category) => category.type === 4) !== undefined &&
        app.price[0].value === 0
      ) {
        if (!_dataTagsList.includes('Free')) {
          _dataTagsList.push('Free');
        }
      }
      _appsList[index].dataTagList = _dataTagsList;
    });
    this.isLoading = false;
    return _appsList;
  }

  initStateMenu() {
    this.categoriesStoreService.getStateMenu().subscribe({
      next: (state: string) => {
        this.menuState = state;
        if (
          this.width === 1024 &&
          this.menuState === 'close' &&
          (this.dataCategory.type === 1 ||
            this.dataCategory.type === 3 ||
            this.dataCategory.type === 4)
        ) {
          this.showText = true;
        }
        if (
          this.width === 1024 &&
          this.menuState === 'open' &&
          (this.dataCategory.type === 1 ||
            this.dataCategory.type === 3 ||
            this.dataCategory.type === 4)
        ) {
          this.showText = false;
        }
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  initPurchasedCategory() {
    this.categoriesStoreService.getPurchasedCategory().subscribe({
      next: (category: CategoryModel) => {
        this.categoryPurchased = category;
        this.categoryPurchasedId = category._id;
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  initMostPurchasedCategory() {
    this.categoriesStoreService.getMostPurchasedCategory().subscribe({
      next: (category: CategoryModel) => {
        this.categoryMostPurchased = category;
        this.categoryMostPurchasedId = category._id;
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  initSpecialCategory() {
    this.categoriesStoreService.getSpecialCategory().subscribe({
      next: (category: CategoryModel) => {
        this.categorySpecial = category;
        this.categorySpecialId = category._id;
        this.buttonUrl = `/category-detail/${this.categorySpecialId}`;
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  initCategoryFeatured() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getFeaturedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categoryFeatured = category;
          this.categoryFeaturedId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategorySuggested() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getSuggestedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categorySuggested = category;
          this.categorySuggestedId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  closeMenu($event): void {
    let url = $event.event.split('/').pop();
    if (this.dataCategoryId !== url) {
      this.isLoading = true;
      this.dataCategory = this.dataCategories.find((category) => category._id === url);
      this.prepareDataCategory(url);
    }
  }

  refreshData() {
    this.isLoading = true;
    this.removeSubscriptions();
    this.initSubscriptions();
  }

  removeSubscriptions() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.removeSubscriptions();
  }
}