<div
  class="{{ className }}__breadcrumb {{
    backToResults !== undefined && backToResults !== '' ? 'back' : ''
  }}">
  <ul class="{{ className }}__breadcrumb-list">
    @if (backToResults !== undefined && backToResults !== '') {
      <li class="{{ className }}__breadcrumb-list-item back">
        <a
          (click)="backToSearchResults()"
          id="button-back-to-results"
          matTooltip="Back to results"
          matTooltipClass="{{ theme }} custom-tooltip"
          matTooltipPosition="below"
          class="{{ className }}__breadcrumb-list-item-link">
          <button class="small" color="primary" disableRipple="true" mat-icon-button>
            <mat-icon>chevron_left</mat-icon>
          </button>
          <span class="{{ className }}__breadcrumb-list-item-link-text">Back to results</span>
        </a>
        <span class="{{ className }}__breadcrumb-list-item-separator-back"></span>
        <!--
        @if (
          breadcrumbs[breadcrumbs.length - 1].id !== undefined &&
          breadcrumbs[breadcrumbs.length - 1].id !== routerId
        ) {
          <a
            (click)="
              back(
                breadcrumbs[breadcrumbs.length - 1].key + '/' + breadcrumbs[breadcrumbs.length - 1].id
              )
            "
            [routerLink]="[
              breadcrumbs[breadcrumbs.length - 1].key + '/' + breadcrumbs[breadcrumbs.length - 1].id
            ]"
            id="button-back-to-{{ breadcrumbs[breadcrumbs.length - 1].key }}"
            matTooltip="Back to {{ breadcrumbs[breadcrumbs.length - 1].value }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="below"
            class="{{ className }}__breadcrumb-list-item-link">
            <button class="small" color="primary" disableRipple="true" mat-icon-button>
              <mat-icon>chevron_left</mat-icon>
            </button>
            <span class="{{ className }}__breadcrumb-list-item-link-text">Back</span>
          </a>
        }
        @if (
          breadcrumbs[breadcrumbs.length - 1].id !== undefined &&
          breadcrumbs[breadcrumbs.length - 1].id === routerId
        ) {
          <a
            (click)="back(breadcrumbs[breadcrumbs.length - 2].key)"
            [routerLink]="[breadcrumbs[breadcrumbs.length - 2].key]"
            id="button-back-to-{{ breadcrumbs[breadcrumbs.length - 2].key }}"
            matTooltip="Back to {{ breadcrumbs[breadcrumbs.length - 2].value }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="below"
            class="{{ className }}__breadcrumb-list-item-link">
            <button class="small" color="primary" disableRipple="true" mat-icon-button>
              <mat-icon>chevron_left</mat-icon>
            </button>
            <span class="{{ className }}__breadcrumb-list-item-link-text">Back</span>
          </a>
        }
        -->
      </li>
    }
    @for (bread of breadcrumbs; track $index) {
      <li class="{{ className }}__breadcrumb-list-item">
        @if (bread.id === undefined) {
          <a
            (click)="navigateTo(bread.key)"
            [routerLink]="[bread.key]"
            id="button-go-{{ bread.key }}"
            matTooltip="Go {{ bread.value }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="below"
            class="{{ className }}__breadcrumb-list-item-link">
            <mat-icon class="{{ className }}__breadcrumb-list-item-link-icon">
              {{ bread.icon }}
            </mat-icon>
            <span class="{{ className }}__breadcrumb-list-item-link-text">{{ bread.value }}</span>
          </a>
        }
        @if (bread.id !== undefined && bread.id !== routerId) {
          @if (
            categorySpecialId !== undefined &&
            categoryPurchasedId !== undefined &&
            bread.id !== categorySpecialId &&
            bread.id !== categoryPurchasedId
          ) {
            <mat-icon class="{{ className }}__breadcrumb-list-item-separator">
              chevron_right
            </mat-icon>
          }

          <a
            (click)="navigateTo(bread.key + '/' + bread.id)"
            [routerLink]="[bread.key + '/' + bread.id]"
            id="button-go-{{ bread.key }}"
            matTooltip="Go {{ bread.value }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="below"
            class="{{ className }}__breadcrumb-list-item-link">
            @if (bread.icon !== '') {
              <mat-icon class="{{ className }}__breadcrumb-list-item-link-icon">
                {{ bread.icon }}
              </mat-icon>
            }
            <span class="{{ className }}__breadcrumb-list-item-link-text">{{ bread.value }}</span>
          </a>
        }
      </li>
    }
  </ul>
</div>