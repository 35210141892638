<ng-container *ngIf="type === 'vertical'">
  <div class="{{ theme }} {{ className }}__wrapper vertical ">
    <div
      class="{{ className }}__skeleton vertical-img skeleton {{
        size !== undefined ? size : ''
      }}"></div>
    <div class="{{ className }}__skeleton vertical-title skeleton"></div>
    <div class="{{ className }}__skeleton vertical-description skeleton"></div>
    <div
      class="{{ className }}__skeleton vertical-description skeleton"
      style="margin-bottom: 20px"></div>
    <div class="{{ className }}__skeleton vertical-barbara-icon skeleton"></div>
    <div class="{{ className }}__skeleton vertical-categories-list skeleton"></div>
  </div>
</ng-container>
<ng-container *ngIf="type === 'horizontal'">
  <div class="{{ theme }} {{ className }}__wrapper horizontal">
    <div class="{{ className }}__wrapper-col">
      <div class="{{ className }}__skeleton horizontal-img skeleton"></div>
    </div>
    <div class="{{ className }}__wrapper-col last">
      <div class="{{ className }}__skeleton horizontal-title skeleton"></div>
      <div class="{{ className }}__skeleton horizontal-description skeleton"></div>
      <div class="{{ className }}__wrapper-row">
        <div class="{{ className }}__skeleton horizontal-barbara-icon skeleton"></div>
        <div class="{{ className }}__skeleton horizontal-categories-list skeleton"></div>
      </div>
    </div>
  </div>
</ng-container>