import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { commonLabels } from 'app/common/common.labels';
import { CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { AuthService } from '../../../../common/services/auth.service';
import { StorageService } from '../../../../common/services/storage.service';
import { CategoriesService } from '../../../../common/services/categories.service';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { ResizeWindowService } from '../../../../common/services/resize-window.service';
import { KeycloakService } from 'keycloak-angular';
import { SnackMessageService } from '../../../../common/services/snackMessage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ApplicationsSearchObservableService } from '../../../../common/services/applications-search-observable.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-main-layout',
  templateUrl: 'main-layout.component.html',
  styleUrls: ['main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() theme: string;
  @Input() showMenu? = true;
  @Input() showMenuEcosystem? = false;
  @Input() showMenuUser? = false;
  @Input() userName: string;
  @Input() userAvatar: string;
  @Input() searchValue: string;
  @Input() isAdmin? = false;
  @Input() isCompanyAdmin? = false;
  @ViewChild('sidenav') sidenav: MatSidenav;

  className = 'main-layout';
  labels: CommonLabels = commonLabels;
  isLoading = false;
  dataCategory: CategoryModel[];
  dataCategories = [];
  categoryFeaturedId;
  categoryPurchasedId;
  categoryMostPurchasedId;
  categorySpecialId;
  categorySuggestedId;
  arraySubscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private readonly resizeWindowService: ResizeWindowService,
    private storageService: StorageService,
    private applicationsSearchObservableService: ApplicationsSearchObservableService,
    private categoriesService: CategoriesService,
    private categoriesStoreService: CategoriesStoreService,
    private snackMessageService: SnackMessageService,
    private readonly keycloak: KeycloakService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.showMenu = this.width > 600;
    this.categoriesStoreService.setStateMenu('close');
    this.initCategories();
    this.searchValue = this.storageService.getStoreSearch();
    if (this.searchValue !== '') {
      this.applicationsSearchObservableService.setSearchValue(this.searchValue);
    }
    this.initSearch();
  }

  initCategories() {
    this.arraySubscriptions.push(
      this.categoriesService.getAllCategories().subscribe({
        next: (data) => {
          let _originalData = [];
          _originalData = data;
          this.dataCategories = [];
          this.dataCategory = [];
          this.dataCategory = data;
          //filtramos por privada y habilitada al servicio llegan todas
          this.dataCategory = _originalData.map((category) => {
            category.applications = this.filterCategoryApplicationsPrivateAndEnabled(
              category.applications
            );
            return category;
          });
          this.dataCategory.forEach((category) => {
            category.applications.forEach((app) => {
              let _appCategories = [];
              let _dataTagsList = [];
              //Rellenamos las categorias de las aplicaciones solo llega id
              app.categories.forEach((cat) => {
                let _cat = _originalData.find((c) => c._id === cat);
                if (_cat !== undefined) {
                  cat = {
                    _id: _cat._id,
                    name: _cat.name,
                    icon: _cat.icon,
                    order: _cat.order,
                    type: _cat.type
                  };
                  _appCategories.push(cat);
                  //Añadimos las tags que se necesitan en las cards
                  if (cat.type === 3) {
                    if (!_dataTagsList.includes('Best Seller')) {
                      _dataTagsList.push('Best Seller');
                    }
                  }
                  if (cat.type === 2) {
                    if (!_dataTagsList.includes('In library')) {
                      _dataTagsList.push('In library');
                    }
                  }
                  if (cat.type === 4 && app.price !== undefined && app.price.length > 0) {
                    if (app.price[0].value === 0) {
                      if (!_dataTagsList.includes('Free')) {
                        _dataTagsList.push('Free');
                      }
                    }
                  }
                }
              });
              if (_appCategories.length > 0) {
                app.categories = _appCategories;
              }
              if (_dataTagsList.length > 0) {
                app.dataTagList = _dataTagsList;
              }
            });
            //Con las categorías actualizadas seteamos los observables de las especiales
            this.updateCategoriesSpecials(category);
            this.dataCategories.push(category);
          });
          this.categoriesStoreService.setCategoryList(this.dataCategories);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  filterCategoryApplicationsPrivateAndEnabled(categoryApplicationsList) {
    let _privateAndEnabledApps = [];
    categoryApplicationsList.forEach((application) => {
      if (!application.isPublic && application.enabled) {
        _privateAndEnabledApps.push(application);
      }
    });
    return _privateAndEnabledApps;
  }

  updateCategoriesSpecials(category) {
    switch (category.type) {
      case 1:
        this.categoriesStoreService.setFeaturedCategoryId(category._id);
        this.categoriesStoreService.setFeaturedCategory(category);
        this.categoryFeaturedId = category._id;
        break;
      case 2:
        this.categoriesStoreService.setPurchasedCategoryId(category._id);
        this.categoriesStoreService.setPurchasedCategory(category);
        this.categoryPurchasedId = category._id;
        break;
      case 3:
        this.categoriesStoreService.setMostPurchasedCategoryId(category._id);
        this.categoriesStoreService.setMostPurchasedCategory(category);
        this.categoryMostPurchasedId = category._id;
        break;
      case 4:
        this.categoriesStoreService.setSpecialCategoryId(category._id);
        this.categoriesStoreService.setSpecialCategory(category);
        this.categorySpecialId = category._id;
        break;
      case 5:
        this.categoriesStoreService.setSuggestedCategoryId(category._id);
        this.categoriesStoreService.setSuggestedCategory(category);
        this.categorySuggestedId = category._id;
        break;
      default:
        //standard
        break;
    }
  }

  initSearch() {
    this.arraySubscriptions.push(
      this.applicationsSearchObservableService.getSearchValue().subscribe({
        next: (value: string) => {
          this.searchValue = value;
        },
        error: (error) => {
          console.error(error);
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  closeMenu($event): void {
    if ($event.action !== 'closeMenu') {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    } else {
      this.categoriesStoreService.setStateMenu('close');
      this.showMenu = false;
    }

    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  openMenu($event): void {
    this.categoriesStoreService.setStateMenu('open');
    this.showMenu = true;
    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.open();
  }

  clearSearch(e) {
    this.applicationsSearchObservableService.setSearchValue(e.value);
    this.storageService.removeStorePersistentSearch();
    this.storageService.removeStoreSearch();
    this.router.navigate(['home']);
  }

  search(e) {
    this.applicationsSearchObservableService.setSearchValue(e.value);
    this.storageService.removeStorePersistentSearch();
    this.storageService.setStoreSearch(e.value);
    this.router.navigate(['results']);
  }

  closeMenuEcosystem(): void {
    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  openMenuEcosystem(): void {
    this.showMenuEcosystem = true;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  closeMenuUser(): void {
    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  openMenuUser(): void {
    this.showMenuEcosystem = false;
    this.showMenuUser = true;
    this.sidenav.close();
  }

  closeMenuMobile(): void {
    this.showMenu = false;
    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  openMenuMobile(): void {
    this.showMenu = false;
    this.showMenuEcosystem = false;
    this.showMenuUser = true;
    this.sidenav.close();
  }

  openWindow(url, target) {
    this.showMenuEcosystem = !this.showMenuEcosystem;
    window.open(url, '_blank');
  }

  closeSession(): void {
    this.logout();
    this.storageService.removelocalStorageData();
    this.authService.setUserLoggedIn(false);
    this.router.navigate(['/public/_home']);
  }

  public logout() {
    this.keycloak.logout();
  }

  ngOnDestroy() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}