import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { ApplicationsSearchObservableService } from '../../services/applications-search-observable.service';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() categoryPurchasedId?: string;
  @Input() categorySpecialId?: string;
  className = 'breadcrumbs';
  theme: string = 'marketplace-default';
  breadcrumbs = [];
  backToResults: string;
  routerId: string;

  constructor(
    private readonly router: Router,
    private applicationsSearchObservableService: ApplicationsSearchObservableService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.breadcrumbs = this.storageService.getBread();
    this.backToResults = this.storageService.getStorePersistentSearch();
    this.routerId = this.router.url.split('/').pop();
  }

  back(url: string) {
    this.storageService.removeLastBread();
    this.router.navigate([url]);
  }

  backToSearchResults() {
    this.storageService.setStoreSearch(this.backToResults);
    this.applicationsSearchObservableService.setSearchValue(this.backToResults);
    this.storageService.removeStorePersistentSearch();
    this.router.navigate(['results']);
  }

  navigateTo(url: string) {
    let id = url.split('/').pop();
    this.storageService.setCategoryId(id);
    this.storageService.removeStorePersistentSearch();
    this.router.navigate([url]);
  }
}